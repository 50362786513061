import { TooltipModule } from 'primeng/tooltip';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { AlertComponent } from './components/alert/alert.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { AlertService } from './services/alert.service';
import { MessageService } from 'primeng/api';
import { SecurePipe } from './pipes/secure.pipe';
import { StringJoinPipe } from './pipes/string-join.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ColorPickerModule } from 'primeng/colorpicker';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputAutocompleteComponent } from './input-helpers/input-autocomplete/input-autocomplete.component';
import { InputAutoCompleteService } from './input-helpers/services/input-autocomplete.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

const components = [
  NavMenuComponent,
  AlertComponent,
  ValidationMessageComponent,
  InputAutocompleteComponent,
];

const modules = [
  CommonModule,
  MenuModule,
  AutoCompleteModule,
  ToastModule,
  FormsModule,
  ReactiveFormsModule,
  TableModule,
  CalendarModule,
  CheckboxModule,
  DropdownModule,
  PdfJsViewerModule,

  DialogModule,
  SelectButtonModule,
  MultiSelectModule,
  SignaturePadModule,
  ColorPickerModule,
  OverlayPanelModule,
  TooltipModule,
  ConfirmDialogModule,
  RadioButtonModule,
  DragDropModule,
];

const directives = [

];

const pipes = [
  SecurePipe,
  StringJoinPipe,
  DurationPipe,
];


const services = [
  AlertService,
  MessageService,
  ConfirmationService,
  InputAutoCompleteService,
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  providers: [
  ],
  exports: [
    ...modules,
    ...directives,
    ...components,
    ...pipes
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        ...services
      ]
    };
  }
}
