<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <i *ngIf="uploadingFile" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-warning btn-lo" (click)="uploadFile()" [disabled]="uploadingFile" *ngIf="carrier">Upload now</button>


      <i *ngIf="exportingExcel" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-secondary ml-2 " (click)="exportToExcel()" [disabled]="exportingExcel">Export</button>

      <a class="btn btn-secondary ml-2 " [routerLink]="'/benefitPlanEDI/tramissions'">Back</a>

    </div>
    <h2 class="mb-3"> {{ carrier || 'Benefit Plan Transmission' }} Members Report</h2>
    <div class="row mb-3" *ngIf="list?.additionalProperties?.hasChanges">
      <div class="col-12">
        <h5 class="text-warning">There changes pending transmission</h5>
      </div>
    </div>
    <div class="row mb-3" *ngIf="list?.additionalProperties?.dateSent">
      <div class="col-3 col-xl-1">
        <h5>Carrier:</h5>
      </div>
      <div class="col-3 col-xl-2  ">
        <h5>{{list?.additionalProperties?.carrier}}</h5>
      </div>

      <div class="col-3 col-xl-2">
        <h5>Date Sent:</h5>
      </div>
      <div class="col-3 col-xl-2">
        <h5>
          {{
list?.additionalProperties?.dateSent | date: 'MM/dd/yyyy'
          }}
        </h5>
      </div>

      <div class="col-3 col-xl-2">
        <h5>File Name:</h5>
      </div>
      <div class="col-9 col-xl-3  ">
        <h5>{{list?.additionalProperties?.fileName}}</h5>
      </div>

    </div>
    <div class="row mb-3" *ngIf="!list?.additionalProperties?.dateSent">
      
      <div class="col-6">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h5>Subscriber Plan:</h5>
          </div>
          <div class="col-8">
            <select [(ngModel)]="filterType" (ngModelChange)="filterTypeChanged()" [ngModelOptions]="{standalone: true}" class="form-control ml-2 w-100 mb-2">
              <option value="Current">Current</option>
              <option value="Removed">Previous or Ended</option>
              <option value="OpenEnrolment">Next year</option>
              <option value="All">All</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="filteredList" [responsive]="true" [paginator]="true" [rows]="1000"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadList($event)"
             [tableStyle]="{ 'min-width': '60rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'Name'">Name <p-sortIcon [field]="'Name'"></p-sortIcon></th>
          <th [pSortableColumn]="'SSN'">SSN <p-sortIcon [field]="'SSN'"></p-sortIcon></th>
          <th [pSortableColumn]="'BirthDate'">Birth Date / Gender <p-sortIcon [field]="'BirthDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmploymentEndDate'">Employment <p-sortIcon [field]="'EmploymentEndDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'BenefitBeginDate'">Begin Date <p-sortIcon [field]="'BenefitBeginDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'BenefitEndDate'">End Date <p-sortIcon [field]="'BenefitEndDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'PlanCoverage'">Plan<p-sortIcon [field]="'PlanCoverage'"></p-sortIcon></th>
          <th [pSortableColumn]="'PhoneNumber'">Phone<p-sortIcon [field]="'PhoneNumber'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmailAddress'">Email<p-sortIcon [field]="'EmailAddress'"></p-sortIcon></th>
          <th [pSortableColumn]="'Address'">Address <p-sortIcon [field]="'Address'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">Name</span>
            {{list.name}}
          </td>
          <td>
            <span class="ui-column-title">SSN</span>
            {{this.formatSSN(list.ssn)}}
          </td>
          <td>
            <span class="ui-column-title">Birth Date / Gender</span>
            {{list.birthDate  | date: 'MM/dd/yyyy'}} {{list.gender}}
          </td>
          <td>
            <span class="ui-column-title">Employment</span>
            <span>
              {{list.employmentBeginDate | date: 'MM/dd/yyyy'}}
            </span>
            <span class=" text-danger" *ngIf="list.employmentEndDate">
              - {{list.employmentEndDate | date: 'MM/dd/yyyy'}}
            </span>
            {{list.employmentStatusCode}}
          </td>
          <td>
            <span class="ui-column-title">BenefitBeginDate</span>
            {{list.benefitBeginDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">BenefitEndDate</span>
            {{list.benefitEndDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Plan</span>
            <div *ngFor="let health of list.healthCoverage">
              {{health.planType}} {{health.planCoverage}} {{health.coverageLevelCode}}
            </div>
          </td>
          <td>
            <span class="ui-column-title">Phone Number</span>
            {{list.phoneNumber}}
          </td>
          <td>
            <span class="ui-column-title">Email Address</span>
            {{list.emailAddress}}
          </td>
          <td>
            <span class="ui-column-title">Address</span>
            {{list.address}}
          </td>
        </tr>
        <tr *ngFor="let dependent of list.dependents" class="font-italic text-secondary">
          <td>
            <span class="ml-2 ">
              {{dependent.name}}
            </span>
          </td>
          <td>
            {{this.formatSSN(dependent.ssn)}}
          </td>
          <td>
            {{dependent.birthDate  | date: 'MM/dd/yyyy'}} {{dependent.gender}}
          </td>
          <td>
            {{dependent.relationship}}
          </td>
          <td>
            <span class="ui-column-title">BenefitBeginDate</span>
            {{dependent.benefitBeginDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">BenefitEndDate</span>
            {{dependent.benefitEndDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Plan</span>
            <div *ngFor="let health of dependent.healthCoverage">
              {{health.planType}} {{health.planCoverage}} {{health.coverageLevelCode}}
            </div>
          </td>
          <td>
            <span class="ui-column-title">Phone Number</span>
            {{dependent.phoneNumber}}
          </td>
          <td>
            <span class="ui-column-title">Email Address</span>
            {{dependent.emailAddress}}
          </td>
          <td>
            <span class="ui-column-title">Address</span>
            {{dependent.address}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          {{list?.additionalProperties?.description}} <br/>
          {{list?.additionalProperties?.notes}}
        </div>
      </ng-template>
    </p-table>
  </div>
</form>
