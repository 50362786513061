
<form id="formEmployeeReview">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div>
          <h1>Employee Review - {{oversightData.employeeLastName}}, {{oversightData.employeeFirstName}}</h1>
        </div>
      </div>
      <div class="row" *ngIf="!canReview">
        <div class="col-12 alert alert-warning">
          You are not authorized to oversight  {{oversightData.employeeFirstName}} {{oversightData.employeeLastName}}.
        </div>
      </div>
      <div class="row" *ngIf="canReview && !oversightData?.isOpen">
        <div class="col-12 alert alert-warning">
          Oversight period is currently closed. You can create a new review beginning {{oversightData?.openDate | date: 'MM/dd/yyyy' || ''}}.
        </div>
      </div>
      <div class="row" *ngIf="canReview && oversightData?.isOpen">
        <div class="col-md-2 col-sm-12 mt-2">
          <b>Template</b>
        </div>
        <div class="col-md-10 col-sm-12">
          <p-dropdown class="w-100" styleClass="form-control mt-2 w-100" [options]="templateAvailables" [(ngModel)]="templateReviews"  (ngModelChange)="changedTemplate()" [ngModelOptions]="{standalone: true}" placeholder="Select a template..."></p-dropdown>
        </div>
      </div>
      <div *ngIf="canReview && oversightData?.isOpen && templateReviews && templateReviews.template">
        <hr />
        <pre *ngIf="templateReviews.template.jobTitles && templateReviews.template.jobTitles.length > 0">The {{templateReviews.template.jobTitles[0]}}(s) support the {{generalSettings?.businessName}} Mission & Values by striving each day to meet the following Key Results Areas and Key Performance Indicators.</pre>
        <pre>{{templateReviews.template.description}}</pre>

        <div *ngFor="let kra of templateReviews.template.kras; let kraIndex=index">
          <div [style.backgroundColor]="kra.valueColor?.colorRGB" [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">
            <h2>#{{kraIndex+1}} KRA</h2>
            <pre [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">{{kra.description}}</pre>
          </div>


          <div *ngIf="kra.reason">
            <strong>Why...</strong>
            <pre>{{kra.reason}}</pre>
          </div>
          <div *ngIf="kra.corePlay">
            <strong>How/Core Play...</strong>
            <pre>{{kra.corePlay}}</pre>
          </div>
          <div *ngIf="kra.measurement">
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kra.measurement}}</pre>
          </div>



          <div *ngFor="let kpi of kra.kpis; let kpiIndex=index" class=" ml-2">
            <h3>#{{kraIndex+1}}.{{kpiIndex+1}} KPI</h3>
            <pre>{{kpi.description}}</pre>
            <strong>Why...</strong>
            <pre [style.backgroundColor]="kpi.valueColor?.colorRGB" [style.color]="blackOrWhite(kpi.valueColor?.colorRGB)">{{kpi.reason}}</pre>
            <strong>How/Core Play...</strong>
            <pre>{{kpi.corePlay}}</pre>
            <div class="w-100" *ngIf="kpi.ethicsCodes && kpi.ethicsCodes.length > 0">
              <div class="d-flex m-2 w-100" [ngClass]="{'bg-warning': ec.highlight}" *ngFor="let ec of kpi.ethicsCodes">
                <strong class="col-2">{{ec.section}}</strong>
                <pre class="col-9">{{ec.description}}</pre>
              </div>
            </div>
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kpi.measurement}}</pre>
            <div *ngFor="let kpiCategory of kpi.categories; let categoryIndex=index" class="w-100  ml-2">
              <h5>{{kpiCategory.category.category}}</h5>
              <div class="row">
                <div class="col-lg-8 col-md-4"></div>
                <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                  <small><strong>{{(review.reviewDate !== null) ? (review.reviewDate | date: 'MM/dd/yyyy') : (review.createdDate | date: 'MM/dd/yyyy')}}</strong></small>
                  <i *ngIf="review.reviewDate === null && review.incomplete === false" title="Not Submitted" style="color: orange;" class="fas fa-hourglass-half"></i>
                  <i *ngIf="review.reviewDate === null && review.incomplete" title="Incomplete" style="color: red;" class="fas fa-hourglass-end"></i>
                </div>
                <div class="col-lg-1 col-md-2"><strong>Oversight</strong></div>
              </div>
              <div class="w-100" *ngFor="let measureable of kpiCategory.measureables; let i=index">
                <div class="row m-2">
                  <div class="col-lg-8 col-md-4">
                    <pre>{{measureable.measureable}}</pre>
                  </div>
                  <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                    <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                  </div>
                  <div class="col-lg-1 col-md-2">
                    <select class="form-control w-100" [(ngModel)]="measureable.answer" (change)='calcScore()' [ngModelOptions]="{standalone: true}">
                      <option value=""></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>

                </div>
                <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
                  <div class="row m-2">
                    <div class="col-lg-8 col-md-4">
                      <pre>{{child.measureable}}</pre>
                    </div>
                    <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                      <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                    </div>
                    <div class="col-lg-1 col-md-2">
                      <select class="form-control w-100" [(ngModel)]="child.answer" (change)='calcScore()' [ngModelOptions]="{standalone: true}">
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>

                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>


          <div class="row" *ngIf="kra.measureables && kra.measureables.length > 0">
            <div class="col-lg-8 col-md-4"></div>
            <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
              <small><strong>{{(review.reviewDate !== null) ? (review.reviewDate | date: 'MM/dd/yyyy') : (review.createdDate | date: 'MM/dd/yyyy')}}</strong></small>
              <i *ngIf="review.reviewDate === null && review.incomplete === false" title="Not Submitted" style="color: orange;" class="fas fa-hourglass-half"></i>
              <i *ngIf="review.reviewDate === null && review.incomplete" title="Incomplete" style="color: red;" class="fas fa-hourglass-end"></i>
            </div>
            <div class="col-lg-1 col-md-2"><strong>Oversight</strong></div>
          </div>
          <div class="w-100" *ngFor="let measureable of kra.measureables; let i=index">
            <div class="row m-2">
              <div class="col-lg-8 col-md-4">
                <pre>{{measureable.measureable}}</pre>
              </div>
              <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
              </div>
              <div class="col-lg-1 col-md-2">
                <p-checkbox [(ngModel)]="measureable.answer" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              </div>

            </div>
            <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
              <div class="row m-2">
                <div class="col-lg-8 col-md-4">
                  <pre>{{child.measureable}}</pre>
                </div>
                <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                  <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                </div>
                <div class="col-lg-1 col-md-2">
                  <p-checkbox [(ngModel)]="child.answer" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>

              </div>
            </div>
            <hr />
          </div>
          <div class="row" *ngIf="kra.optionSet && kra.optionSet.scoreOptions && kra.optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
            <div class="col-md-10 col-sm-12">
              <p-dropdown styleClass="form-control w-100 mb-2" [showClear]="true" [options]="kra.optionSet.scoreOptions" optionLabel="scoreOptionDescription" [(ngModel)]="kra.scoreOption" (ngModelChange)='calcScore()' optionValue="scoreOptionValue" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
              </p-dropdown>
            </div>
          </div>
        </div>


        <div class="row ml-4">
          <div class="col-lg-8 col-md-4">
            <strong>Score</strong>
          </div>
          <div class="col-lg-1 col-md-2 sm" *ngFor="let review of templateReviews.reviews; let i=index">
            <small><strong>{{(review.reviewDate !== null) ? (review.score) : ('-')}}/{{templateReviews.template.maximumScore}}</strong></small>
          </div>
          <div class="col-lg-1 col-md-2"><strong>{{score}} / {{templateReviews.template.maximumScore}}</strong></div>
        </div>
        <hr />

        <div class="row mb-2">
          <div class="col-md-2 col-sm-12">
            Comments:
          </div>
          <div class="col-md-10 col-sm-12">
            <textarea class="form-control w-100" [(ngModel)]="comments" (ngModelChange)="changeComment()" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 col-sm-12">
            Area to focus on:
          </div>
          <div class="col-md-10 col-sm-12">
            <textarea class="form-control w-100" [(ngModel)]="focusArea" (ngModelChange)="changeFocusArea()" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>

        <div *ngIf="(oversightReviews && oversightReviews.length > 0 && oversightData.canSeeApproval)" class="mt-2 alert alert-secondary">
          <b>2nd Oversight Review</b>
          <div *ngFor="let oversightReview of oversightReviews; let i=index">
            <div class="row">
              <div class="col-12">
                <span class="text-danger" *ngIf="oversightReview.approved == false">Rejected</span>
                <span class="text-success" *ngIf="oversightReview.approved">Approved</span>
                 by {{oversightReview.reviewerName}} on {{oversightReview.reviewedDate | date: 'shortDate' }}
              </div>
              <pre class="col-12">
                  {{oversightReview.reviewComments}}
                </pre>
            </div>
            <div class="row" *ngIf="oversightReview.fixedAt">
              <div class="col-12">
                Fixed by {{oversightReview.fixedByUserName}} on {{oversightReview.fixedAt | date: 'shortDate' }}
              </div>
              <pre class="col-12">
                  {{oversightReview.FixComments}}
                </pre>
            </div>
          </div>

          <div class="row" *ngIf="oversightData.canSeeApproval && hasOpenFixRequest">
            <div class="col-md-2 col-sm-12">
              Fix Comments
            </div>
            <div class="col-md-10 col-sm-12">
              <textarea class="form-control w-100" [(ngModel)]="fixComments" maxlength="2000" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>

        </div>

        <div *ngIf="templateReviews.template.kras.length > 0">
          <div class="row" *ngIf="templateReviews.template.kras[0].optionSet && templateReviews.template.kras[0].optionSet.scoreOptions && templateReviews.template.kras[0].optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
            <ul class="w-100 m-2" *ngFor="let option of templateReviews.template.kras[0].optionSet.scoreOptions; let i=index">
              <li>
                {{option.scoreOptionValue}} - {{option.scoreOptionSummaryDescription}}
              </li>
            </ul>
          </div>
        </div>

        <div class="row">

          <div class="col d-flex">
            <button type="button" [disabled]="loading || savingOversightReview" class="btn btn-success ml-2 " (click)="save()">Submit</button>
            <button type="button" [disabled]="loading || savingOversightReview" class="btn btn-primary ml-2 " (click)="saveToLater()">Save And Finish Later</button>
            <i *ngIf="!!loading || !!savingOversightReview" class="material-icons rotate text-muted">loop</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
