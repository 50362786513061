<div class="card mb-2">
    <div class="card-body">
        <i *ngIf="!ticketTypeForm || loadingForm" class="material-icons rotate text-muted">loop</i>
        <div *ngIf="ticketTypeForm" class="position-relative">

            <button class="btn btn-secondary float-right pointer" routerLink="/ticket-types/list">Back</button>
            <h2>{{ isEditMode ? 'Edit' : 'Create New' }} Ticket Type</h2>

            <div class="row">
                <div class="col-12">
                    <form [formGroup]="ticketTypeForm" *ngIf="ticketTypeForm">

                        <!-- Ticket Type Name -->
                        <div class="row">
                            <div class="col-12 col-lg-6 ">

                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Ticket Type Name</b>
                                    </div>
                                    <div class="col-8">
                                        <input id="nameInput" type="text" class="form-control mt-2"
                                            formControlName="name" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'name'" [name]="'name'"
                                            [label]="'Ticket Type Name'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Description <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <input id="descriptionInput" type="text" class="form-control mt-2"
                                            formControlName="description" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'description'" [name]="'description'"
                                            [label]="'Description'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Color <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <p-dropdown styleClass="mt-2" [options]="colorOptions"
                                            formControlName="color">
                                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                                <div [class]="'flex align-items-center gap-2 '+selectedOption.value">
                                                    
                                                    <div [class]="'color-square '+selectedOption.value">
                                                    </div>
                                                    <div class="display-inline">{{ selectedOption.label }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-color pTemplate="item">
                                                <div [class]="'flex align-items-center gap-2 '+color.value">
                                                    
                                                    <div [class]="'color-square '+color.value">
                                                    </div>
                                                    <div class="display-inline">{{ color.label }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <app-validation-message [form]="ticketTypeForm" [path]="'color'" [name]="'color'"
                                            [label]="'Color'"> </app-validation-message>
                                    </div>

                                </div>
                            </div>

                            <div class="w-100"></div>

                            <!-- Custom Fields Table -->
                            <div class="col-12 col-lg-8 mt-4">
                                <h4>Custom Fields</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addCustomField()">Add Custom
                                    Field</button>

                                <p-table [value]="customFields.controls" *ngIf="customFields.length > 0"
                                    class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center">Field Name</th>
                                            <th class="text-center" style="width: 250px;">Field Type</th>
                                            <th class="text-center">Other settings</th>
                                            <th class="text-center" style="width: 170px;">Only Management</th>
                                            <th class="text-center" style="width: 120px;">Actions</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-customField let-i="rowIndex">
                                        <tr [formGroup]="customField">
                                            <td>
                                                <input type="text" class="form-control" formControlName="name" />
                                            </td>
                                            <td>
                                                <p-dropdown styleClass="mt-2" [options]="fieldTypesOptions"
                                                    formControlName="fieldTypeId"></p-dropdown>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="customField.get('fieldTypeId').value == 4 ">
                                                    <span class="font-weight-bold">Field Options</span>
                                                    <textarea class="form-control"
                                                        formControlName="fieldOptions"></textarea>
                                                    <small>Add each option in one line</small>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="customField.get('fieldTypeId').value == 4 || customField.get('fieldTypeId').value == 5 ">
                                                    <div>
                                                        <span class="font-weight-bold mr-2">Allow other</span>
                                                        <p-checkbox binary="true"
                                                            formControlName="allowOther"></p-checkbox>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <div class="row justify-content-center align-items-center">
                                                    <p-checkbox binary="true"
                                                    formControlName="showOnlyManagement"></p-checkbox>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="removeCustomField(i)">Remove</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="customFields.length === 0">No custom fields assigned. Add custom fields
                                    using the button above.</div>
                            </div>

                            <!-- Ticket Statuses Table -->
                            <div class="col-12 col-lg-4  mt-4">
                                <h4>Ticket Statuses</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addStatus()">Add
                                    Status</button>
                                                                
                                <div *ngIf="ticketStatuses.length > 0" class="p-datatable-sm">
                                    <div class="row">
                                        <div class="col-6 text-center border background-light">Status Name</div>
                                        <div class="col-2 text-center border background-light">Is Final</div>
                                        <div class="col-2 text-center border background-light">Is Default</div>
                                        <div class="col-2 text-center border background-light">Actions</div>
                                    </div>
                                    <form [formGroup]="ticketStatuses">
                                        <div cdkDropList (cdkDropListDropped)="drop($event)" >
                                            <div *ngFor="let status of ticketStatuses.controls; let i = index" cdkDrag>
                                                <div class="row pt-2 pb-2 pl-0 pr-0 border" [formGroupName]="i">
                                                    <td class="col-6 d-flex justify-content-center align-items-center">
                                                        <i class="fa fa-bars barsHandle m-0 p-0 mr-3"></i>
                                                        <input type="text" class="form-control" formControlName="name" />
                                                    </td>
                                                    <td class="col-2 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <p-checkbox binary="true" formControlName="isFinal"></p-checkbox>
                                                        </div>
                                                    </td>
                                                    <td class="col-2 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <p-checkbox binary="true" formControlName="isDefault"></p-checkbox>
                                                        </div>
                                                    </td>
                                                    <td class="col-2 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <button type="button" class="btn btn-danger"
                                                                (click)="removeStatus(i)">Remove</button>
                                                        </div>
                                                    </td>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="ticketStatuses.length === 0">No ticket statuses assigned. Add statuses using
                                    the button above.</div>
                            </div>

                            <div class="col-12 col-lg-12 mt-4">
                                <div class="d-flex justify-content-between w-100">
                                    <div>
                                        <button type="button" class="btn mt-3 mb-3" [class.btn-primary]="assignmentType == 'User'"
                                        [class.btn-secondary]="assignmentType != 'User'" (click)="selectAssignmentType('User')">Users</button>
                                        <button type="button" class="btn mt-3 mb-3 ml-2" [class.btn-primary]="assignmentType == 'Title'"
                                        [class.btn-secondary]="assignmentType != 'Title'" (click)="selectAssignmentType('Title')">Job Titles</button>
                                        <button type="button" class="btn mt-3 mb-3 ml-2"[class.btn-primary]="assignmentType == 'Role'"
                                        [class.btn-secondary]="assignmentType != 'Role'" (click)="selectAssignmentType('Role')">Roles</button>
                                    </div>
                                    <div>
                                        <button type="button float-right" *ngIf="assignmentType == 'User'" class="btn btn-primary mb-3" (click)="addUser()">Add User</button>
                                        <button type="button float-right" *ngIf="assignmentType == 'Title'" class="btn btn-primary mb-3" (click)="addJobTitle()">Add Job Title</button>
                                        <button type="button float-right" *ngIf="assignmentType == 'Role'" class="btn btn-primary mb-3" (click)="addRole()">Add Role</button>
                                    </div>
                                </div>
                                <div class="row ticket-type-assignments">
                                    <p-table [value]="users.controls" *ngIf="users.length > 0 && assignmentType == 'User'" class="p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center" style="width: 250px;">User</th>
                                                <th class="text-center">Can Be Assigned</th>
                                                <th class="text-center">Can Create Tickets</th>
                                                <th class="text-center">Can Manage Tickets</th>
                                                <th class="text-center">Can Change Created By</th>
                                                <th class="text-center">Receive Notifications</th>
                                                <th class="text-center" style="width: 120px;">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-user let-i="rowIndex">
                                            <tr [formGroup]="user">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="usersList"
                                                        formControlName="userID" placeholder="Select user..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canBeAssigned"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canChangeCreatedBy"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="removeUser(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="users.length === 0 && assignmentType == 'User'">No users assigned. Add users using the button above.
                                    </div>

                                    
                                    <p-table [value]="roles.controls" *ngIf="roles.length > 0 && assignmentType == 'Role'" class="p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center" style="width: 250px;">Role</th>
                                                <th class="text-center">Can Be Assigned</th>
                                                <th class="text-center">Can Create Tickets</th>
                                                <th class="text-center">Can Manage Tickets</th>
                                                <th class="text-center">Can Change Created By</th>
                                                <th class="text-center">Receive Notifications</th>
                                                <th class="text-center" style="width: 120px;">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-role let-i="rowIndex">
                                            <tr [formGroup]="role">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="roleList"
                                                        formControlName="roleID" placeholder="Select role..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canBeAssigned"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canChangeCreatedBy"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="removeRole(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="roles.length === 0 && assignmentType == 'Role'">No roles assigned. Add roles using the button above.
                                    </div>

                                    
                                    <p-table [value]="jobTitles.controls" *ngIf="jobTitles != null && jobTitles.length > 0 && assignmentType == 'Title'" class="p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center" style="width: 250px;">Job Title</th>
                                                <th class="text-center">Can Be Assigned</th>
                                                <th class="text-center">Can Create Tickets</th>
                                                <th class="text-center">Can Manage Tickets</th>
                                                <th class="text-center">Can Change Created By</th>
                                                <th class="text-center">Receive Notifications</th>
                                                <th class="text-center" style="width: 120px;">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-jobTitle let-i="rowIndex">
                                            <tr [formGroup]="jobTitle">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="titleList"
                                                        formControlName="jobTitleID" placeholder="Select job title..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canBeAssigned"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canChangeCreatedBy"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="removeJobTitle(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="jobTitles.length === 0 && assignmentType == 'Title'">No job titles assigned. Add job titles using the button above.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="ticketTypeForm.invalid"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>