import { saveUserSignature, saveUserSignatureFailure, saveUserSignatureSuccess } from './../onboarding/onboarding.actions';
import { SpokenLanguageModel, UserTypeModel } from './../../../models/userProfileModel';
import { ValidatorRequiredIfBool, ValidatorConfirmField, ValidatorRequiredTrueIfBool, ValidatorRequiredNotEmptyIfBool } from 'src/app/helpers/validators';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { UserProfileModel, RenderingProviderModel, RaceEthnicityModel, JobTitleModel, MaritalStatusModel, StatutoryClassModel, AcaClassificationModel, EeoJobCategoryModel } from '../../../models/userProfileModel';
import {
  loadProfile, loadProfileSuccess, saveProfileSuccess,
  updateProfileForm, getPhotoSuccess, uploadPhoto, uploadPhotoSuccess, changePassword, getReturnEquipmentAlerts, getReturnEquipmentAlertsSuccess,
  changePasswordSuccess, updateProfileBCIForm, saveProfile, saveProfileFail, updateProfileCBSForm,
    updateProfilePRISMForm, updateProfileI9Form, loadRaceOptionsSuccess, loadJobTitlesSuccess, loadProfileOptionsSuccess, loadUserTypes, loadUserTypesSuccess, saveSignature, saveSignatureFailure, saveSignatureSuccess, updateProfileW4Form, loadSpokenLanguageOptionsSuccess
} from './profile.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { State } from '../';
import { getAllControlsErrors, formatPhoneNumber } from 'src/app/helpers/utils';
import { FormError } from 'src/app/models/utilModels';

export interface ProfileState {
  profile: UserProfileModel;
  formErrors: FormError[];
  photoUrl: string;
  uploadingPhoto: boolean;
  changingPassword: boolean;
  savingProfile: boolean;
  raceOptions: RaceEthnicityModel[];
  jobTitles: JobTitleModel[];
  maritalStatusOptions: MaritalStatusModel[];
  statutoryClassOptions: StatutoryClassModel[];
  eeoJobCategoryOptions: EeoJobCategoryModel[];
  acaClassificationOptions: AcaClassificationModel[];
  userTypes: UserTypeModel[];
  savingSignature: boolean;
  spokenLanguageOptions: SpokenLanguageModel[];
  returnEquipmentAlert: string;
  returnEquipmentAlertCount: number;
  returnEquipmentAlertAllCount: number;
}

const initialState: ProfileState = {
  profile: null,
  formErrors: null,
  photoUrl: '',
  uploadingPhoto: false,
  changingPassword: false,
  savingProfile: false,
  raceOptions: [],
  jobTitles: [],
  maritalStatusOptions: [],
  statutoryClassOptions: [],
  eeoJobCategoryOptions: [],
  acaClassificationOptions: [],
  userTypes: [],
  savingSignature: false,
  spokenLanguageOptions: [],
  returnEquipmentAlert: "",
  returnEquipmentAlertCount: 0,
  returnEquipmentAlertAllCount: 0,
};

export const profileReducer = createReducer(initialState,
  on(loadProfile, (state, action) => ({ ...state })),
  on(loadProfileSuccess, (state, action) => {
    let formErrors;
    if (action.profile.employee) {
      const form = initProfileForm(action.profile);
      formErrors = getAllControlsErrors(form);
    }
    return {
      ...state,
      profile: action.profile,
      formErrors
    };
  }),
  on(updateProfileForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),
  on(updateProfileBCIForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),

  on(updateProfileCBSForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),
  on(updateProfilePRISMForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),

  on(updateProfileI9Form, (state, action) => {
    return returnProfileReduced(state, action);
  }),
  on(updateProfileW4Form, (state, action) => {
    return returnProfileReduced(state, action);
  }),
  on(saveProfile, (state, action) => ({ ...state, savingProfile: true })),
  on(saveProfileSuccess, (state, action) => ({ ...state, profile: action.profile, savingProfile: false })),
  on(saveProfileFail, (state, action) => ({ ...state, savingProfile: false })),
  on(getPhotoSuccess, (state, action) => ({ ...state, ...{ photoUrl: action.url } })),

  on(uploadPhoto, (state, action) => ({ ...state, ...{ uploadingPhoto: true } })),
  on(uploadPhotoSuccess, (state, action) => ({ ...state, ...{ uploadingPhoto: false } })),
  on(changePassword, (state, action) => ({ ...state, ...{ changingPassword: true } })),
  on(changePasswordSuccess, (state, action) => ({ ...state, ...{ changingPassword: false } })),

  on(loadSpokenLanguageOptionsSuccess, (state, action) => {
    return {
      ...state,
      spokenLanguageOptions: action.spokenLanguageOptions
    };
  }),

  on(loadRaceOptionsSuccess, (state, action) => {
    return {
      ...state,
      raceOptions: action.raceOptions
    };
  }),
  on(getReturnEquipmentAlertsSuccess, (state, action) => {
    return {
      ...state,
      returnEquipmentAlert: action.alert.alert,
      returnEquipmentAlertCount: action.alert.count,
      returnEquipmentAlertAllCount: action.alert.allCount,
    };
  }),
  on(loadJobTitlesSuccess, (state, action) => {
    return {
      ...state,
      jobTitles: action.jobTitles
    };
  }),

  on(loadUserTypesSuccess, (state, action) => {
    return {
      ...state,
      userTypes: action.userTypes
    };
  }),

  on(loadProfileOptionsSuccess, (state, action) => {
    return {
      ...state,
      maritalStatusOptions: action.profileOptions.maritalStatusOptions,
      statutoryClassOptions: action.profileOptions.statutoryClassOptions,
      eeoJobCategoryOptions: action.profileOptions.eeoJobCategoryOptions,
      acaClassificationOptions: action.profileOptions.acaClassificationOptions,
    };
  }),

  on(saveSignature, (state, action) => ({ ...state, savingSignature: true })),
  on(saveSignatureSuccess, (state, action) => ({ ...state, savingSignature: false })),
  on(saveSignatureFailure, (state, action) => ({ ...state, savingSignature: false })),
  on(saveUserSignature, (state, action) => ({ ...state, savingSignature: true })),
  on(saveUserSignatureSuccess, (state, action) => ({ ...state, savingSignature: false })),
  on(saveUserSignatureFailure, (state, action) => ({ ...state, savingSignature: false })),

);

function returnProfileReduced(state, action) {
  const newUser = { ...state.profile.user, ...action.userValues };
  const newEmployee = { ...state.profile.employee, ...action.employeeValues };
  const newEmployeeFederalTaxInformation = { ...state.profile.employeeFederalTaxInformation, ...action.employeeFederalTaxInformationValues };
  const newProfile = { ...state.profile, ...{ user: newUser, employee: newEmployee , employeeFederalTaxInformation: newEmployeeFederalTaxInformation } };
  const newState = { ...state, ...{ profile: newProfile, formErrors: action.formErrors } };
  return newState;
}

export function profileReducerFunc(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}


export function initProfileForm(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
      email: new FormControl(profile.user.email, [Validators.maxLength(255), Validators.email]),
      phoneNumber: new FormControl(formatPhoneNumber(profile.user.phoneNumber),
        [Validators.required, Validators.maxLength(255),
        Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$')]),
      preferedName: new FormControl(profile.user.preferedName, [Validators.maxLength(255)]),
      middleName: new FormControl(profile.user.middleName, [Validators.maxLength(255)]),
      previousName: new FormControl(profile.user.previousName, [Validators.maxLength(255)]),
      doAllowEmailNotifications: new FormControl(profile.user.doAllowEmailNotifications),
      doAllowSMSNotifications: new FormControl(profile.user.doAllowSMSNotifications),
    }),
    employee: new FormGroup({
      personalEmail: new FormControl(profile.employee.personalEmail, [Validators.required, Validators.maxLength(255), Validators.email]),
      tShirtSize: new FormControl(profile.employee.tShirtSize, [Validators.required, Validators.maxLength(255)]),
      address: new FormControl(profile.employee.address, [Validators.required, Validators.maxLength(255)]),
      addressExtra: new FormControl(profile.employee.addressExtra, [Validators.maxLength(255)]),
      city: new FormControl(profile.employee.city, [Validators.required, Validators.maxLength(255)]),
      state: new FormControl(profile.employee.state, [Validators.required, Validators.maxLength(255)]),
      zipCode: new FormControl(profile.employee.zipCode,
        [Validators.required, Validators.maxLength(255), Validators.pattern('^([0-9]{5,10}|[0-9]{3,5}-[0-9]{3,5})$')]),
      mailingAddress: new FormControl(profile.employee.mailingAddress, [Validators.required, Validators.maxLength(255)]),
      mailingAddressExtra: new FormControl(profile.employee.mailingAddressExtra, [Validators.maxLength(255)]),
      mailingCity: new FormControl(profile.employee.mailingCity, [Validators.required, Validators.maxLength(255)]),
      mailingState: new FormControl(profile.employee.mailingState, [Validators.required, Validators.maxLength(255)]),
      mailingZipCode: new FormControl(profile.employee.mailingZipCode, [Validators.required, Validators.maxLength(255)]),
      emergencyContactName: new FormControl(profile.employee.emergencyContactName, [Validators.required, Validators.maxLength(255)]),
      emergencyContactPhone: new FormControl(profile.employee.emergencyContactPhone,
        [Validators.required, Validators.maxLength(255), ,
        Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$')]),
      emergencyContactRelation: new FormControl(profile.employee.emergencyContactRelation,
        [Validators.required, Validators.maxLength(255)]),
      emergencyContact2Name: new FormControl(profile.employee.emergencyContact2Name, [Validators.maxLength(255)]),
      emergencyContact2Phone: new FormControl(profile.employee.emergencyContact2Phone, [Validators.maxLength(255),
      Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$')]),
      emergencyContact2Relation: new FormControl(profile.employee.emergencyContact2Relation, [Validators.maxLength(255)]),
      bankName: new FormControl(profile.employee.bankName, [Validators.maxLength(255)]),
      bankAccountNumber: new FormControl(profile.employee.bankAccountNumber, [Validators.maxLength(255)]),
      bankRoutingNumber: new FormControl(profile.employee.bankRoutingNumber, [Validators.maxLength(255)]),
      bankAccountNumberConfirm: new FormControl(profile.employee.bankAccountNumber),
      bankRoutingNumberConfirm: new FormControl(profile.employee.bankRoutingNumber),
      bankAccountType: new FormControl(profile.employee.bankAccountType, [Validators.maxLength(255)]),
      hasDisability: new FormControl(profile.employee.hasDisability, [Validators.required]),
      veteranStatus: new FormControl(profile.employee.veteranStatus, [Validators.required]),
      raceEthnicityID: new FormControl(profile.employee.raceEthnicityID, [Validators.required]),
      genderAtBirth: new FormControl(profile.employee.genderAtBirth, [Validators.required]),
      differentGenderIdentity: new FormControl(profile.employee.differentGenderIdentity),
      preferredGenderIdentity: new FormControl(profile.employee.preferredGenderIdentity, [Validators.maxLength(255)]),
      customPronouns: new FormControl(profile.employee.customPronouns, [Validators.maxLength(255)]),
      stateOfBirth: new FormControl(profile.employee.stateOfBirth, [Validators.required]),
      orientationDate: new FormControl(profile.employee.orientationDate ? new Date(profile.employee.orientationDate) : null),
      i9CompletionDate: new FormControl(profile.employee.i9CompletionDate ? new Date(profile.employee.i9CompletionDate) : null),
      eVerifyCompletionDate: new FormControl(profile.employee.eVerifyCompletionDate ? new Date(profile.employee.eVerifyCompletionDate) : null),

      suffix: new FormControl(profile.employee.suffix),
      department: new FormControl(profile.employee.department),
      county: new FormControl(profile.employee.county),
      country: new FormControl(profile.employee.country),
      mailingCounty: new FormControl(profile.employee.mailingCounty),
      mailingCountry: new FormControl(profile.employee.mailingCountry),
      tobaccoUser: new FormControl(profile.employee.tobaccoUser, [Validators.required]),
      statutoryClassID: new FormControl(profile.employee.statutoryClassID),
      eeoJobCategoryID: new FormControl(profile.employee.eeoJobCategoryID),
      maritalStatusID: new FormControl(profile.employee.maritalStatusID),
      acaClassificationID: new FormControl(profile.employee.acaClassificationID),

    }, {
      validators: [ValidatorConfirmField('bankAccountNumberConfirm', 'bankAccountNumber'),
      ValidatorConfirmField('bankRoutingNumberConfirm', 'bankRoutingNumber')]
    })
  });

  return form;
}


export function initProfileBCIForm(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
      middleName: new FormControl(profile.user.middleName, [Validators.maxLength(255)]),
      previousName: new FormControl(profile.user.previousName, [Validators.maxLength(255)]),
    }),
    employee: new FormGroup({
      personalEmail: new FormControl(profile.employee.personalEmail, [Validators.required, Validators.maxLength(255)]),
      address: new FormControl(profile.employee.address, [Validators.required, Validators.maxLength(255)]),
      addressExtra: new FormControl(profile.employee.addressExtra, [Validators.maxLength(255)]),
      city: new FormControl(profile.employee.city, [Validators.required, Validators.maxLength(255)]),
      state: new FormControl(profile.employee.state, [Validators.required, Validators.maxLength(255)]),
      zipCode: new FormControl(profile.employee.zipCode,
        [Validators.required, Validators.maxLength(255), Validators.pattern('^([0-9]{5,10}|[0-9]{3,5}-[0-9]{3,5})$')]),
      birthdate: new FormControl(profile.employee.birthdate, [Validators.required]),
      ssn: new FormControl(profile.employee.ssn, [Validators.maxLength(255)]),
      bciNewRenewTransfer: new FormControl(profile.employee.bciNewRenewTransfer, [Validators.required]),
      bciBeenArrested: new FormControl(profile.employee.bciBeenArrested, [Validators.required]),
      bciBeenInvestigated: new FormControl(profile.employee.bciBeenInvestigated, [Validators.required]),
      bciLiveScanCompleted: new FormControl(profile.employee.bciLiveScanCompleted, [Validators.required]),
      bciTCN: new FormControl(profile.employee.bciTCN, [Validators.maxLength(255)]),
      bciIsYouthResidential: new FormControl(profile.employee.bciIsYouthResidential, [Validators.required]),
      bciLivedOutside: new FormControl(profile.employee.bciLivedOutside),
      bciLivedOutsideCertify: new FormControl(profile.employee.bciLivedOutsideCertify),
      bciLivedOutsideList: new FormControl(profile.employee.bciLivedOutsideList,
        [Validators.maxLength(2000)]),

    }, {
      validators: [ValidatorRequiredIfBool('bciLivedOutside', 'bciIsYouthResidential'),
      ValidatorRequiredTrueIfBool('bciLivedOutsideCertify', 'bciLivedOutside'),
      ValidatorRequiredIfBool('bciLivedOutsideList', 'bciLivedOutside')
      ]
    })
  });

  return form;
}


export function initProfileCBSForm(profile: UserProfileModel) {
  const form = new FormGroup({
    employee: new FormGroup({
      cbsApplicationNumber: new FormControl(profile.employee.cbsApplicationNumber, [Validators.pattern(/^[0-9]\d*$/)]),
    })
  });

  return form;
}

export function initProfilePRISMForm(profile: UserProfileModel) {
  const form = new FormGroup({
    employee: new FormGroup({
      prismRequestDate: new FormControl(profile.employee.prismRequestDate, [Validators.required]),
      prismEffectiveDate: new FormControl(profile.employee.prismEffectiveDate, [Validators.required]),
    })
  });

  return form;
}



export function initProfileI9Form(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
      phoneNumber: new FormControl(formatPhoneNumber(profile.user.phoneNumber),
        [Validators.required, Validators.maxLength(255),
        Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$')]),

      middleName: new FormControl(profile.user.middleName, [Validators.maxLength(255)]),
      previousName: new FormControl(profile.user.previousName, [Validators.maxLength(255)]),
    }),
    employee: new FormGroup({
      address: new FormControl(profile.employee.address, [Validators.required, Validators.maxLength(255)]),
      addressExtra: new FormControl(profile.employee.addressExtra, [Validators.maxLength(255)]),
      city: new FormControl(profile.employee.city, [Validators.required, Validators.maxLength(255)]),
      state: new FormControl(profile.employee.state, [Validators.required, Validators.maxLength(255)]),
      zipCode: new FormControl(profile.employee.zipCode,
        [Validators.required, Validators.maxLength(255), Validators.pattern('^([0-9]{5,10}|[0-9]{3,5}-[0-9]{3,5})$')]),
      birthdate: new FormControl(profile.employee.birthdate, [Validators.required]),
      ssn: new FormControl(profile.employee.ssn, [Validators.maxLength(255)]),
      i9USSituation: new FormControl(profile.employee.i9USSituation, [Validators.required]),
      i9USCIS: new FormControl(profile.employee.i9USCIS),
      i9AlienAuthorizationExpirationDate: new FormControl((profile.employee.i9AlienAuthorizationExpirationDate
        ? new Date(profile.employee.i9AlienAuthorizationExpirationDate).toISOString().substr(0, 10)
        : null)),
      i9i94AdmissionNumber: new FormControl(profile.employee.i9i94AdmissionNumber),
      i9ForeignPassportNumber: new FormControl(profile.employee.i9ForeignPassportNumber),
      i9ForeignPassportCountry: new FormControl(profile.employee.i9ForeignPassportCountry),
      i9UsedPreparer: new FormControl(profile.employee.i9UsedPreparer, [Validators.required]),
      personalEmail: new FormControl(profile.employee.personalEmail),
      preparerFirstname: new FormControl(profile.employee.preparerFirstname, [Validators.maxLength(255)]),
      preparerLastname: new FormControl(profile.employee.preparerLastname, [Validators.maxLength(255)]),
      preparerAddress: new FormControl(profile.employee.preparerAddress, [Validators.maxLength(255)]),
      preparerCity: new FormControl(profile.employee.preparerCity, [Validators.maxLength(255)]),
      preparerState: new FormControl(profile.employee.preparerState, [Validators.maxLength(255)]),
      preparerZip: new FormControl(profile.employee.preparerZip, [Validators.maxLength(10)]),
    }, { validators: [i9Validator()] }
    )
  });

  return form;
}

export function i9Validator(): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (form.get('i9USSituation').value === 'PermanentResident') {
      if (!form.get('i9USCIS').value) {
        form.get('i9USCIS').setErrors({ required: { value: form.get('i9USCIS').value } });
        return { required: { value: form.get('i9USCIS').value } };
      } else {
        form.get('i9USCIS').setErrors(null);
      }
    } else if (form.get('i9USSituation').value === 'AuthorizedToWork') {
      if (
        (form.get('i9USCIS').value
          && (form.get('i9i94AdmissionNumber').value
            || form.get('i9ForeignPassportNumber').value
            || form.get('i9ForeignPassportCountry').value)
        )
        ||
        (form.get('i9i94AdmissionNumber').value
          && (form.get('i9USCIS').value
            || form.get('i9ForeignPassportNumber').value
            || form.get('i9ForeignPassportCountry').value)
        )
      ) {
        form.get('i9USCIS').setErrors({ genericMessage: 'Fill ONLY one of the fields. Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number' });
        return { genericMessage: 'Fill only one of the fields. Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number' };
      } else if (
        (!form.get('i9USCIS').value
          && !form.get('i9i94AdmissionNumber').value
          && !form.get('i9ForeignPassportNumber').value)
      ) {
        form.get('i9USCIS').setErrors({ genericMessage: 'Fill AT LEAST one of the fields: Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number' });
        return { genericMessage: 'Fill at least one  of the fields. Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number' };
      } else {
        form.get('i9USCIS').setErrors(null);
      }

      if (form.get('i9ForeignPassportNumber').value
        && !form.get('i9ForeignPassportCountry').value) {
        form.get('i9ForeignPassportCountry').setErrors({ genericMessage: 'Country is required if you filled Foreign Passport Number' });
        return { genericMessage: 'Country is required if you filled Foreign Passport Number' };
      } else {
        form.get('i9ForeignPassportCountry').setErrors(null);
      }
    }
    else {
      form.get('i9USCIS').setErrors(null);
      form.get('i9ForeignPassportCountry').setErrors(null);
    }

    if (form.get('i9UsedPreparer').value) {
      if (!form.get('preparerFirstname').value) {
        form.get('preparerFirstname').setErrors({ required: { value: form.get('preparerFirstname').value } });
      } else {
        form.get('preparerFirstname').setErrors(null);
      }
      if (!form.get('preparerLastname').value) {
        form.get('preparerLastname').setErrors({ required: { value: form.get('preparerLastname').value } });
      } else {
        form.get('preparerLastname').setErrors(null);
      }
      if (!form.get('preparerAddress').value) {
        form.get('preparerAddress').setErrors({ required: { value: form.get('preparerAddress').value } });
      } else {
        form.get('preparerAddress').setErrors(null);
      }
      if (!form.get('preparerCity').value) {
        form.get('preparerCity').setErrors({ required: { value: form.get('preparerCity').value } });
      } else {
        form.get('preparerCity').setErrors(null);
      }
      if (!form.get('preparerState').value) {
        form.get('preparerState').setErrors({ required: { value: form.get('preparerState').value } });
      } else {
        form.get('preparerState').setErrors(null);
      }
      if (!form.get('preparerZip').value) {
        form.get('preparerZip').setErrors({ required: { value: form.get('preparerZip').value } });
      } else {
        form.get('preparerZip').setErrors(null);
      }

    } else {
      if (form.get('preparerFirstname')) {
        form.get('preparerFirstname').setErrors(null);
      }
      if (form.get('preparerLastname')) {
        form.get('preparerLastname').setErrors(null);
      }
      if (form.get('preparerAddress')) {
        form.get('preparerAddress').setErrors(null);
      }
      if (form.get('preparerCity')) {
        form.get('preparerCity').setErrors(null);
      }
      if (form.get('preparerState')) {
        form.get('preparerState').setErrors(null);
      }
      if (form.get('preparerZip')) {
        form.get('preparerZip').setErrors(null);
      }
    }
  };
}




export function initProfileW4Form(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
      firstName: new FormControl(profile.user.firstName, [Validators.maxLength(255)]),
      middleName: new FormControl(profile.user.middleName, [Validators.maxLength(255)]),
      lastName: new FormControl(profile.user.lastName, [Validators.maxLength(255)]),
    }),
    employee: new FormGroup({
      ssn: new FormControl(profile.employee.ssn, [Validators.maxLength(255)]),
      address: new FormControl(profile.employee.address, [Validators.required, Validators.maxLength(255)]),
      addressExtra: new FormControl(profile.employee.addressExtra, [Validators.maxLength(255)]),
      city: new FormControl(profile.employee.city, [Validators.required, Validators.maxLength(255)]),
      state: new FormControl(profile.employee.state, [Validators.required, Validators.maxLength(255)]),
      zipCode: new FormControl(profile.employee.zipCode, [Validators.required, Validators.maxLength(255), Validators.pattern('^([0-9]{5,10}|[0-9]{3,5}-[0-9]{3,5})$')])
    }),
    employeeFederalTaxInformation: new FormGroup({
      filingStatus: new FormControl(profile.employeeFederalTaxInformation.filingStatus, [Validators.required]),
      higherRate: new FormControl(profile.employeeFederalTaxInformation.higherRate, [Validators.required]),
      childDependentsAmount: new FormControl(profile.employeeFederalTaxInformation.childDependentsAmount, [Validators.maxLength(12), Validators.pattern('^([0-9]*)$')]),
      otherDependentsAmount: new FormControl(profile.employeeFederalTaxInformation.otherDependentsAmount, [Validators.maxLength(12), Validators.pattern('^([0-9]*)$')]),
      totalDependentsAmount: new FormControl(profile.employeeFederalTaxInformation.totalDependentsAmount, [Validators.maxLength(12), Validators.pattern('^([0-9]*)$')]),
      otherIncomeAmount: new FormControl(profile.employeeFederalTaxInformation.otherIncomeAmount, [Validators.maxLength(12), Validators.pattern('^((-?[0-9]+)|(-?[0-9]+[.][0-9]{1,2}))?$')]),
      deductionsAmount: new FormControl(profile.employeeFederalTaxInformation.deductionsAmount, [Validators.maxLength(12), Validators.pattern('^((-?[0-9]+)|(-?[0-9]+[.][0-9]{1,2}))?$')]),
      extraWithholdingAmount: new FormControl(profile.employeeFederalTaxInformation.extraWithholdingAmount, [Validators.maxLength(12), Validators.pattern('^((-?[0-9]+)|(-?[0-9]+[.][0-9]{1,2}))?$')]),
      extraStateWithholdingAmount: new FormControl(profile.employeeFederalTaxInformation.extraStateWithholdingAmount, [Validators.maxLength(12), Validators.pattern('^((-?[0-9]+)|(-?[0-9]+[.][0-9]{1,2}))?$')]),
    }, { validators: [W4Validator()] }
    )
  });

  return form;
}

export function W4Validator(): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (form.get('childDependentsAmount').value && !form.get('childDependentsAmount').errors) {
      var vl = parseFloat(form.get('childDependentsAmount').value);
      if (!isNaN(vl) && vl % 2000 != 0) {
        form.get('childDependentsAmount').setErrors({ genericMessage: 'Child Dependents Amount must be a multiple of 2000.' });
        return { genericMessage: 'Child Dependents Amount must be a multiple of 2000.' };
      }
    }
    if (form.get('otherDependentsAmount').value && !form.get('otherDependentsAmount').errors) {
      var vl = parseFloat(form.get('otherDependentsAmount').value);
      if (!isNaN(vl) && vl % 500 != 0) {
        form.get('otherDependentsAmount').setErrors({ genericMessage: 'Other Dependents Amount must be a multiple of 500.' });
        return { genericMessage: 'Other Dependents Amount must be a multiple of 500.' };
      }
    }

    if (form.get('otherDependentsAmount').value && !form.get('otherDependentsAmount').errors
      && form.get('childDependentsAmount').value && !form.get('childDependentsAmount').errors
      && form.get('totalDependentsAmount').value && !form.get('totalDependentsAmount').errors    ) {
      var vl = parseFloat(form.get('totalDependentsAmount').value);
      var vl2 = parseFloat(form.get('otherDependentsAmount').value) + parseFloat(form.get('childDependentsAmount').value);
      if (!isNaN(vl) && vl != vl2) {
        form.get('totalDependentsAmount').setErrors({ genericMessage: 'Total Dependents Amount must be the sum of child and other dependents amount.' });
        return { genericMessage: 'Total Dependents Amount must be the sum of child and other dependents amount.' };
      }
    }
  };
}


