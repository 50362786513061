import { UserModel } from './userModel';
import { OfferLetterModel } from './offerLetterModel';

export class UserProfileModel {
  userId?: string;
  user?: UserModel;
  employee?: EmployeeModel;
  offerLetter: OfferLetterModel;
  renderingProvider?: RenderingProviderModel;
  employeeFederalTaxInformation?: EmployeeFederalTaxInformationModel;
  pendingEquipmentCount?: number;
  pendingDocumentsCount?: number;
  pendingRequiredDocumentsCount?: number;
  pendingPayrollFixRequestsCount?: number;
  staffInjuriesQueueCount?: number;
  newSignedOfferLettersCount?: number;
  notSubmittedInternApplicationsCount?: number;
  submittedInternApplicationsNeedingMoreInfoCount?: number;
  overdueCoachLogsCount: number;
  //i9FormOnboardingDocUserId?: number;
  i9FormInfoFilled: boolean;
  i9FormSignedDate?: string;
  //i9FormSignedOnboardingDocUserId?: number;

  i9FormSubmissionId?: number;
  i9FormId?: number;

  isOnBoarding?: boolean;
  viewSignatureUrl: string;

  allDocsSigned?: boolean;
  allUploaded?: boolean;
  unansweredSurveyCount?: number;
  newAnsweredReferenceSurveyCount?: number;
  paylocityTransmissionsWithErrors?: number;
  benefitsEnrollmentPending?: boolean;
  rejectedUploadCount?: number;
  caqhExpirationDate?: Date;
  caqhQuestionnaireSentDate?: Date;
  pendingRequiredFormsCount?: number;
  pendingFormsCount?: number;
  pendingInternApplications?: number;

  otherLanguages: UserSpokenLanguageModel[];
  clientLoginEmail: string;
  driversLicenseExpired?: boolean;
  hasMedicalPlan?: boolean;
  doAllowEmailNotifications?: boolean;
  doAllowSMSNotifications?: boolean;
  pendingCoachingLogAck?: number;
}

export class RenderingProviderModel {
  npi?: string;
  dea?: string;
  exemptFromClinicalOversight?: boolean;
  taxonomyCode?: string;
}

export class EmployeeFederalTaxInformationModel {
  w4FormYear: number;
  filingStatus?: string;
  higherRate?: boolean;
  childDependentsAmount?: number;
  otherDependentsAmount?: number;
  totalDependentsAmount?: number;
  otherIncomeAmount?: number;
  deductionsAmount?: number;
  extraWithholdingAmount?: number;
  extraStateWithholdingAmount?: number;
  taxCalculationCode?: string;
  taxAmount?: number;
  taxPercentage?: number;
  taxExemptions?: number;
  onboardingDocUserId?: number;
}

export class EmployedDurationModel {
  id: number;
  employeeID: number;
  trainingStartDate?: Date;
  terminationDate: Date;
}

export class EmployeeModel {
  id?: number;
  userID?: number;
  birthdate?: Date;
  stateOfBirth: string;
  trainingStartDate?: Date;
  driversLicenseExpirationDate?: Date;
  backgroundCheckConfirmationDate?: Date;
  terminationDate?: Date;
  cprEffectiveDate?: Date;

  hasTimeCard?: boolean;
  inTraining?: boolean;
  inRehireProcess?: boolean;

  payrollClassificationId?: number;
  payrollEmployeeNumber?: number;


  ssn: string;
  payRate: number;


  address: string;
  addressExtra: string;
  city: string;
  state: string;
  zipCode: string;

  mailingAddress: string;
  mailingAddressExtra: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;

  tShirtSize: string;
  driverLicenseNumber: string;

  emergencyContactName: string;
  emergencyContactPhone: string;
  emergencyContactRelation: string;
  emergencyContact2Name: string;
  emergencyContact2Phone: string;
  emergencyContact2Relation: string;

  bankName: string;
  bankAccountNumber: string;
  bankRoutingNumber: string;
  bankAccountType: string;

  personalEmail: string;

  bciNewRenewTransfer?: number;
  bciBeenArrested?: boolean;
  bciBeenInvestigated?: boolean;

  bciLiveScanCompleted?: boolean;
  bciTCN: string;
  bciIsYouthResidential?: boolean;
  bciLivedOutside?: boolean;
  bciLivedOutsideCertify?: boolean;
  bciLivedOutsideList: string;

  cbsApplicationNumber?: number;

  prismRequestDate?: Date;
  prismEffectiveDate?: Date;

  isCurrentlyFullTime?: boolean;
  isCurrentlyExempt?: boolean;


  i9USSituation: string;

  i9USCIS: string;
  i9AlienAuthorizationExpirationDate?: string;
  i9i94AdmissionNumber: string;
  i9ForeignPassportNumber: string;
  i9ForeignPassportCountry: string;

  i9UsedPreparer?: boolean;


  hasDisability?: number;
  veteranStatus?: number;
  raceEthnicityID?: number;

  genderAtBirth?: number;
  differentGenderIdentity: boolean;
  preferredGenderIdentity: string;
  customPronouns: string;

  orientationDate: string;

  bciIDType: string;
  bciIDState: string;
  bciIDNumber: string;
  bciIDExpirationDate?: Date;
  bciGender: string;
  bciRace: string;
  bciEyeColor: string;
  bciHairColor: string;
  bciHeight: string;
  bciWeight: string;
  bciLicenseTypes: string;
  bciProgramName: string;
  bciSiteNameOrAddress: string;
  bciProgramPhoneNumber: string;
  i9AdditionalInfo: string;
  i9EmploymentFirstDate?: Date;
  i9OrganizationName: string;
  i9OrganizationAddress: string;
  i9OrganizationCity: string;
  i9OrganizationState: string;
  i9OrganizationZIP: string;
  i9NewFirstName: string;
  i9NewLastName: string;
  i9NewMiddleInitial: string;
  i9RehireDate?: Date;
  i9RehireDocumentTitle: string;
  i9RehireDocumentNumber: string;
  i9RehireExpirationDate?: Date;

  i9ListA1DocumentTitle: string;
  i9ListA1IssuingAuthority: string;
  i9ListA1DocumentNumber: string;
  i9ListA1ExpirationDate?: Date;
  i9ListA2DocumentTitle: string;
  i9ListA2IssuingAuthority: string;
  i9ListA2DocumentNumber: string;
  i9ListA2ExpirationDate?: Date;
  i9ListA3DocumentTitle: string;
  i9ListA3IssuingAuthority: string;
  i9ListA3DocumentNumber: string;
  i9ListA3ExpirationDate?: Date;
  i9ListBDocumentTitle: string;
  i9ListBIssuingAuthority: string;
  i9ListBDocumentNumber: string;
  i9ListBExpirationDate?: Date;
  i9ListCDocumentTitle: string;
  i9ListCIssuingAuthority: string;
  i9ListCDocumentNumber: string;
  i9ListCExpirationDate?: Date;
  eVerifyCompletionDate: string;
  i9CompletionDate: string;


  suffix: string;
  department: string;
  county: string;
  country: string;
  mailingCounty: string;
  mailingCountry: string;
  tobaccoUser: boolean;
  statutoryClassID: number;
  eeoJobCategoryID: number;
  maritalStatusID: number;
  acaClassificationID: number;

  employedDurations: EmployedDurationModel[];

  preparerFirstname: string;
  preparerLastname: string;
  preparerAddress: string;
  preparerCity: string;
  preparerState: string;
  preparerZip: string;

  lastUpdate?: string;
  benefitClassId?: number;
}

export class SpokenLanguageModel {
  id: number;
  language: string;
}

export class RaceEthnicityModel {
  id: number;
  label: string;
}

export class JobTitleModel {
  id: number;
  jobTitle: string;
  archived: boolean;
}
export class TerminatedEmployeeModel {
  firstName: string;
  lastName: string;
  employeeSSN?: string; // should not be null for report
  terminationDate?: Date;
  terminationReason?: string;

  dateString?: string;
  terminationReasonId?: number;
  userId: number; // for editing employee
  subReasonIds?: number[];
}

export class EmployeeCensusModel {
  userId: number; // for editing employee

  employeeSSN?: string; // should not be null for report
  lastName: string;
  firstName: string;
  providerNPI: string;

  middleName?: string;
  suffix?: string;
  birthdate?: Date;
  gender?: string;
  hireDate?: Date;
  class?: string;
  department?: string;
  division?: string;
  businessUnit?: string;
  office?: string;
  workEmail?: string;
  personalEmail?: string;
  payrollGroup?: string;
  annualBaseSalary?: number;
  hourlyRate?: number;
  hoursPerWeek?: number;
  salaryEffectiveDate?: Date;
  annualBenefitSalary?: number;
  annualBenefitSalaryEffectiveDate?: Date;
  acaClassification?: string;
  priorYearW2Earnings?: number;
  statutoryClass?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
  country?: string;
  phoneNumber?: string;
  payrollWorkState?: string;
  benefitWorkState?: string;
  employeeId?: string;
  payrollId?: string;
  timeClockId?: string;
  jobTitle?: string;
  isFullTime?: boolean;
  isExempt?: boolean;
  isCollectivelyBargained?: boolean;
  leaseEmployee?: boolean;
  seasonalEmployee?: boolean;
  eeoJobCategory?: string;
  costCenter?: string;
  worksOutsideUS?: boolean;
  maritalStatus?: string;
  tobaccoUser?: boolean;
  onWellness?: boolean;
  raceEthnicity?: string;
  rehireDate?: Date;
  originalHireDate?: Date;
  affiliateHireDate?: Date;
  retireDate?: Date;

  terminationDate: Date;
  terminationReason?: string;
}


export class MaritalStatusModel {
  id: number;
  maritalStatus: string;
}
export class StatutoryClassModel {
  id: number;
  statutoryClass: string;
}
export class EeoJobCategoryModel {
  id: number;
  eeoJobCategory: string;
}
export class AcaClassificationModel {
  id: number;
  acaClassification: string;
}

export class ProfileOptionsModel {
  maritalStatusOptions: MaritalStatusModel[];
  statutoryClassOptions: StatutoryClassModel[];
  eeoJobCategoryOptions: EeoJobCategoryModel[];
  acaClassificationOptions: AcaClassificationModel[];
}



export class UserTypeModel {
  id: number;
  userType: string;
}

export class UserSpokenLanguageModel {
  id?: number;
  spokenLanguageId?: number;
  moreInfo?: string;
  userId?: number;
}
