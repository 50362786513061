
<form id="formEmployeeReview">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div>
          <h1>Employee Review - {{oversightData.employeeLastName}}, {{oversightData.employeeFirstName}}</h1>
        </div>
      </div>
      <div *ngIf="templateReviews && templateReviews.template">
        <hr />
        <pre *ngIf="templateReviews.template.jobTitles && templateReviews.template.jobTitles.length > 0">The {{templateReviews.template.jobTitles[0]}}(s) support the {{generalSettings?.businessName}} Mission & Values by striving each day to meet the following Key Results Areas and Key Performance Indicators.</pre>
        <pre>{{templateReviews.template.description}}</pre>

        <div *ngFor="let kra of templateReviews.template.kras; let kraIndex=index">
          <div [style.backgroundColor]="kra.valueColor?.colorRGB" [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">
            <h2>#{{kraIndex+1}} KRA</h2>
            <pre [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">{{kra.description}}</pre>
          </div>

          <div *ngIf="kra.reason">
            <strong>Why...</strong>
            <pre>{{kra.reason}}</pre>
          </div>
          <div *ngIf="kra.corePlay">
            <strong>How/Core Play...</strong>
            <pre>{{kra.corePlay}}</pre>
          </div>
          <div *ngIf="kra.measurement">
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kra.measurement}}</pre>
          </div>


          <div *ngFor="let kpi of kra.kpis; let kpiIndex=index" class=" ml-2">
            <h3>#{{kraIndex+1}}.{{kpiIndex+1}} KPI</h3>
            <pre>{{kpi.description}}</pre>
            <strong>Why...</strong>
            <pre [style.backgroundColor]="kpi.valueColor?.colorRGB" [style.color]="blackOrWhite(kpi.valueColor?.colorRGB)">{{kpi.reason}}</pre>
            <strong>How/Core Play...</strong>
            <pre>{{kpi.corePlay}}</pre>
            <div class="w-100" *ngIf="kpi.ethicsCodes && kpi.ethicsCodes.length > 0">
              <div class="d-flex m-2 w-100" [ngClass]="{'bg-warning': ec.highlight}" *ngFor="let ec of kpi.ethicsCodes">
                <strong class="col-2">{{ec.section}}</strong>
                <pre class="col-9">{{ec.description}}</pre>
              </div>
            </div>
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kpi.measurement}}</pre>
            <div *ngFor="let kpiCategory of kpi.categories; let categoryIndex=index" class="w-100  ml-2">
              <h5>{{kpiCategory.category.category}}</h5>
              <div class="row">
                <div class="col-lg-10 col-md-9"></div>
                <div class="col-lg-2 col-md-3 " *ngFor="let review of templateReviews.reviews; let i=index">
                  <small><strong>{{review.reviewDate | date: 'MM/dd/yyyy'}}</strong></small>
                </div>

              </div>
              <div class="w-100" *ngFor="let measureable of kpiCategory.measureables; let i=index">
                <div class="row m-2">
                  <div class="col-lg-10 col-md-9">
                    <pre>{{measureable.measureable}}</pre>
                  </div>
                  <div class="col-lg-2 col-md-3 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                    <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                  </div>

                </div>
                <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
                  <div class="row m-2">
                    <div class="col-lg-10 col-md-9">
                      <pre>{{child.measureable}}</pre>
                    </div>
                    <div class="col-lg-2 col-md-3 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                      <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>



          <div class="row m-2" *ngIf="kra.measureables && kra.measureables.length > 0">
            <div class="col-lg-10 col-md-9"></div>
            <div class="col-lg-2 col-md-3 sm" *ngFor="let review of templateReviews.reviews; let i=index">
              <small><strong>{{review.reviewDate | date: 'MM/dd/yyyy'}}</strong></small>
            </div>
          </div>
          <div class="w-100" *ngFor="let measureable of kra.measureables; let i=index">            
            <div class="row m-2">
              <div class="col-lg-10 col-md-9">                
                <pre>{{measureable.measureable}}</pre>
              </div>
              <div class="col-lg-2 col-md-3 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
              </div>

            </div>
            <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
              <div class="row m-2">
                <div class="col-lg-10 col-md-9">                  
                  <pre>{{child.measureable}}</pre>
                </div>
                <div class="col-lg-2 col-md-3 sm" *ngFor="let review of templateReviews.reviews; let i=index">
                  <small><strong class="p-1">{{getAnswer(review, measureable)}}</strong></small>
                </div>
              </div>
            </div>
            <hr />
          </div>


          <div class="row" *ngIf="kra.optionSet && kra.optionSet.scoreOptions && kra.optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
          </div>

        </div>
        <div class="row ml-4">
          <div class="col-lg-10 col-md-9">
            <strong>Score</strong>
          </div>
          <div class="col-lg-2 col-md-3 " *ngFor="let review of templateReviews.reviews; let i=index">
            <small><strong>{{review.score}}/{{templateReviews.template.maximumScore}}</strong></small>
          </div>
        </div>
        <hr />

        <div *ngFor="let review of templateReviews.reviews; let i=index">
          <div *ngIf="review.comments">
            <div class="row m-2">
              <strong>Comments</strong>
            </div>
            <div class="row m-2">
              <pre class=" w-100">{{review.comments}}</pre>
            </div>
          </div>
          <div *ngIf="review.focusArea">
            <div class="row m-2">
              <strong>Area to focus on</strong>
            </div>
            <div class="row m-2">
              <pre class=" w-100">{{review.focusArea}}</pre>
            </div>
          </div>
        </div>

        <div *ngIf="templateReviews.template && templateReviews.template.kras.length > 0">
          <div class="row" *ngIf="templateReviews.template.kras[0].optionSet && templateReviews.template.kras[0].optionSet.scoreOptions && templateReviews.template.kras[0].optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
            <ul class="w-100 m-2" *ngFor="let option of templateReviews.template.kras[0].optionSet.scoreOptions; let i=index">
              <li>
                {{option.scoreOptionValue}} - {{option.scoreOptionSummaryDescription}}
              </li>
            </ul>
          </div>
        </div>

        <div class="row">

          <div class="col d-flex">
            <button type="button" [disabled]="loading" class="btn btn-secondary ml-2 " (click)="back()">Back</button>
            <i *ngIf="!!loading" class="material-icons rotate text-muted">loop</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
